<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs>
		<ServiceContentBI></ServiceContentBI>
		<Footer></Footer>
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import BreadCrumbs from '@/components/common/BreadCrumbs.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
import ServiceContentBI from '../components/ServiceContentBI.vue';

export default {
	components: {
		MainHeader,
		Footer,
		ServiceContentBI,
		BreadCrumbs,
		MenuIntro,
	},
	data() {
		return {
			crumbs: [
				{ name: 'Home', link: '/' },
				{ name: '서비스', link: '/service' },
				{ name: 'BI-Mining', link: '/serviceBiMining' },
			],
			menuKr: 'FCTS BI-Mining 서비스',
			menuEn: 'FCTS',
		};
	},
	methods: {
		selected(crumb) {
			this.$emit('selected', crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
